import { RecentWorksCard, WorksLinks } from "../../components/Cards";
import { recentWorksData } from "../../DummyData";
import { industryData } from "../../DummyData";
import { useContext } from "react";
import widthContext from "../../context/widthContext";
import { motion } from "framer-motion";

const Work = () => {
  const variants = {
    initial: {
      scale: 0.8,
      opacity: 0,
      x: 100,
    },
    animate: {
      scale: 1,
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  const width = useContext(widthContext);

  return (
    <main className="px-4 sm:px-8 lg:px-16 flex flex-col items-center justify-center gap-16 sm:gap-32">
      <motion.h4
        variants={variants}
        initial="initial"
        animate="animate"
        className="self-start text-2xl sm:text-3xl md:text-4xl lg:text-6xl font-semibold"
      >
        The work we do,
        <br /> and the brands we help.
      </motion.h4>
      <section className="w-full flex items-center  flex-col gap-12">
        <WorksLinks />

        <div className="w-full lg:px-16 flex flex-col justify-center gap-4">
          {width < 640 ? (
            <div className="flex flex-col gap-6  ">
              {recentWorksData.map((item, index) => (
                <RecentWorksCard
                  key={item.alt}
                  header={item.header}
                  headerColor={item.headerColor}
                  text={item.text}
                  textColor={item.textColor}
                  bg={item.bg}
                  img={item.img}
                  alt={item.alt}
                  reward={item.reward}
                  to={item.alt}
                />
              ))}
            </div>
          ) : (
            <div className="flex flex-col gap-8  w-full">
              <div className="flex justify-between gap-8 w-full">
                <div className="flex flex-col gap-8 lg:gap-24">
                  {recentWorksData.map((item, index) => {
                    if (index < 2) {
                      return (
                        <RecentWorksCard
                          key={item.alt}
                          header={item.header}
                          headerColor={item.headerColor}
                          text={item.text}
                          textColor={item.textColor}
                          bg={item.bg}
                          img={item.img}
                          alt={item.alt}
                          reward={item.reward}
                          to={item.alt}
                        />
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
                <div className="flex flex-col gap-8 lg:gap-24 translate-y-14">
                  {recentWorksData.map((item, index) => {
                    if (index > 1 && index < 4) {
                      return (
                        <RecentWorksCard
                          key={item.alt}
                          header={item.header}
                          headerColor={item.headerColor}
                          text={item.text}
                          textColor={item.textColor}
                          bg={item.bg}
                          img={item.img}
                          alt={item.alt}
                          reward={item.reward}
                          to={item.alt}
                        />
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              </div>
              <div className="flex  justify-between gap-8 lg:gap-24 flex-wrap mt-24">
                {recentWorksData.map((item, index) => {
                  if (index > 3) {
                    return (
                      <RecentWorksCard
                        key={item.alt}
                        header={item.header}
                        headerColor={item.headerColor}
                        text={item.text}
                        textColor={item.textColor}
                        bg={item.bg}
                        img={item.img}
                        alt={item.alt}
                        reward={item.reward}
                        to={item.alt}
                      />
                    );
                  }
                })}
              </div>
            </div>
          )}
        </div>
      </section>
      <section className="flex flex-col gap-4 sm:px-16 w-full ">
        <h4 className=" sm:pl-[15vw] sm:pr-[30vw]  font-semibold text-xl sm:text-2xl md:text-3xl lg:text-5xl text-black">
          Industry Sector {width > 768 ? <br /> : null}Experience
        </h4>
        <div className="sm:self-center sm:w-2/3 md:w-1/2">
          <p className="text-xl md:text-2xl pb-8 md:pb-16">
            Our expertise span several sectors both locally and globally.
          </p>
          {
            <div className="flex flex-col sm:flex-row sm:justify-between gap-5 w-full">
              <ul className="flex flex-col   gap-5">
                {industryData.map((item, index) => {
                  if (index < 6) {
                    return (
                      <li key={item} className="flex gap-5 items-center">
                        <span className="w-4 h-4 rounded-full bg-black" />{" "}
                        <p className="text-xl sm:text-2xl">{item}</p>
                      </li>
                    );
                  } else {
                    return null;
                  }
                })}
              </ul>
              <ul className="flex flex-col   gap-5">
                {industryData.map((item, index) => {
                  if (index > 5) {
                    return (
                      <li key={item} className="flex gap-5 items-center">
                        <span className="w-4 h-4 rounded-full bg-black" />{" "}
                        <p className="text-xl sm:text-2xl">{item}</p>
                      </li>
                    );
                  } else {
                    return null;
                  }
                })}
              </ul>
            </div>
          }
        </div>
      </section>
    </main>
  );
};

export default Work;
