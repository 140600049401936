import { IoMdClose } from "react-icons/io";
// import lightLogo from "../../assets/lightLogo.svg";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import LightLogo from "../../components/LightLogo";
import "../../components/lightLogoStyle.css";

const More = () => {
  const navigate = useNavigate();
  const navigateHandler = () => {
    navigate(-1);
  };

  const variants = {
    initial: {},
    animate: {
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.1,
        duration: 0.1,
      },
    },
  };

  const itemss = {
    initial: {
      y: 20,
      opacity: 0,
    },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        y: {
          stiffness: 1,
          velocity: -1000,
        },
      },
    },
  };

  const navVariant = {
    initial: {},
    animate: {
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  const hrVariant = {
    initial: {
      width: 0,
    },
    animate: {
      width: "100%",
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <motion.nav
      variants={variants}
      initial="initial"
      animate="animate"
      className="bg-black flex flex-col gap-12 overflow-y-hidden py-12 px-4  sm:px-8 lg:px-16  justify-between   fixed z-50 top-0 w-[100vw] h-[100vh]"
    >
      <motion.div
        variants={itemss}
        className=" w-full flex justify-between px-4 py-8 "
      >
        <NavLink to="/home" className=" ">
          {/* <img src={animatedDarkLogo} alt="Logo" /> */}
          <LightLogo />
        </NavLink>

        <div
          className="cursor-pointer flex gap-2 text-white"
          onClick={navigateHandler}
        >
          <IoMdClose className="  text-2xl" />
          Cancel
        </div>
      </motion.div>
      <motion.div
        variants={itemss}
        className="flex flex-col justify-center items-center gap-12 "
      >
        <div className="lg:leading-[1.2] font-[500] text-[#9C9C9C] text-3xl md:text-4xl lg:text-6xl text-center sm:w-2/3 md:w-1/2 ">
          Learn more{" "}
          <Link className="text-white  " to="/services">
            {" "}
            <motion.div
              variants={navVariant}
              initial="initial"
              whileHover="animate"
              className="  inline-flex flex-col"
            >
              <p>about us,</p>
              <div className="inline-flex relative flex-col">
                <hr className="bg-[#9C9C9C] m-0 border-0 h-[0.7px] w-full " />
                <motion.hr
                  variants={hrVariant}
                  className="bg-white m-0 border-0 absolute top-0 h-[2px]  "
                />
              </div>
            </motion.div>{" "}
          </Link>{" "}
          get the latest on{" "}
          <a href="https://www.instagram.com/protechadvance/" target="_blank" rel="noopener noreferrer" className="text-white inline-flex flex-col">
            {" "}
            <motion.div
              variants={navVariant}
              initial="initial"
              whileHover="animate"
              className="relative inline-flex flex-col"
            >
              <p>instagram</p>
              <div className="inline-flex relative flex-col">
                <hr className="bg-[#9C9C9C] m-0 border-0 h-[0.7px] w-full " />
                <motion.hr
                  variants={hrVariant}
                  className="bg-white m-0 border-0 absolute top-0 h-[1.5px]  "
                />
              </div>
            </motion.div>{" "}
          </a>{" "}
          or{" "}
          <Link className="text-white " to="/contact">
            {" "}
            <motion.div
              variants={navVariant}
              initial="initial"
              whileHover="animate"
              className="relative inline-flex flex-col"
            >
              <p>contact us</p>
              <div className="inline-flex relative flex-col">
                <hr className="bg-[#9C9C9C] m-0 border-0 h-[0.7px] w-full " />
                <motion.hr
                  variants={hrVariant}
                  className="bg-white m-0 border-0 absolute top-0 h-[1.5px]  "
                />
              </div>
            </motion.div>{" "}
          </Link>
        </div>
      </motion.div>
      <NavLink
        className="text-[#9C9C9C] self-center justify-self-end w-fit text-center text-2xl  "
        to="/home"
      >
        <motion.div
          variants={navVariant}
          initial="initial"
          whileHover="animate"
          className="relative inline-flex flex-col"
        >
          <p>Home</p>
          <div className="inline-flex relative flex-col">
            <hr className="bg-[#9C9C9C] m-0 border-0 h-[0.7px] w-full " />
            <motion.hr
              variants={hrVariant}
              className="bg-black m-0 border-0 absolute top-0 h-[1.5px]  "
            />
          </div>
        </motion.div>
      </NavLink>
    </motion.nav>
  );
};

export default More;
