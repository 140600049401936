import { Outlet } from "react-router-dom";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

const Layout = () => {
  return (
    <main className="flex  flex-col gap-16 md:gap-32 font-general h-full">
      <div className=" sm:sticky sm:top-0 sm:z-50">
        <NavBar />
      </div>
      <Outlet />
      <Footer />
    </main>
  );
};

export default Layout;
