import { useNavigate } from "react-router-dom";
import { FlagSpinner } from "react-spinners-kit";

const SuccessModal = ({ show, success, onClose, error }) => {
  const navigate = useNavigate();
  if (!show) {
    return null;
  }
  return (
    <div
      className="z-50 fixed top-0 right-0 w-screen h-screen flex  items-center justify-center bg-gray-500/50 font-general"
      onClick={() => onClose()}
    >
      {success ? (
        <div
          className="flex flex-col gap-8 items-center bg-white p-12 rounded-md"
          onClick={(e) => e.stopPropagation()}
        >
          <p className=" lg:text-2xl xl:text-4xl text-center">
            Thanks for contacting us, <br /> we'll be in touch with you soon!
          </p>
          <button
            className="py-5 px-8 rounded-[40px] bg-black text-white text-2xl w-fit self-center hover:scale-105 transition-transform"
            onClick={() => {
              navigate("/");
              onClose();
            }}
          >
            Go to home
          </button>
        </div>
      ) : error ? (
        <div
          className="flex flex-col gap-8 items-center bg-white p-12 rounded-md"
          onClick={(e) => e.stopPropagation()}
        >
          <p className=" lg:text-2xl xl:text-4xl text-center">
            Error sending data, <br /> please try again.<br/>
            <span>{error}</span>
          </p>
          <button
            className="py-5 px-8 rounded-[40px] bg-black text-white text-2xl w-fit self-center hover:scale-105 transition-transform"
            onClick={() => {
              // navigate("/");
              onClose();
            }}
          >
           Close
          </button>
        </div>
      ) : (
        <FlagSpinner loading={!success} text="Loading..." color="black" />
      )}
    </div>
  );
};

export default SuccessModal;
