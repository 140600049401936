import { useNavigate, Link, NavLink } from "react-router-dom";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { motion } from "framer-motion";
import { useState } from "react";

export const RecentWorksCard = ({
  img,
  alt,
  header,
  headerColor,
  text,
  textColor,
  bg,
  reward,
  to,
}) => {
  const navigate = useNavigate();
  const navigateHandler = () => {
    navigate(`/works/:${to}`);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      // transition={{ duration: 0.4}}
      onClick={navigateHandler}
      className={`w-full h-[25rem] lg:h-[37.5rem] lg:w-[31rem]
               pt-4 flex flex-col overflow-hidden cursor-pointer ${bg} bg-cover bg-center bg-no-repeat`}
    >
      <div className={`pl-4 ${reward ? "pb-12" : ""} z-10`}>
        <h4 className={`${headerColor} text-xl sm:text-2xl   `}>{header}</h4>
        <p className={`${textColor} text-[10px] sm:text-xs`}>{text}</p>
      </div>
      <div className="self-center hover:scale-125 transition-transform ease-linear">
       {img && <img src={img} alt={alt} />}
      </div>
    </motion.div>
  );
};

export const ServicesCard = ({ icon, header1, header2, text, border }) => {
  return (
    <div
      className={`flex flex-col sm:flex-row sm:justify-evenly grayscale hover:grayscale-0 text-gray-500 hover:text-white transition-colors ease-in-out cursor-pointer   gap-6 py-8 border-b ${
        border ? "border-t" : ""
      } border-[#5E5E5E]`}
    >
      <div className="flex  items-center gap-8">
        <div>
          <img src={icon} alt="" />
        </div>
        <div className="flex flex-col gap-2 font-[500] ">
          <p>{header1}</p>
          <p>{header2}</p>
        </div>
      </div>
      <p className="text-[15px] lg:w-[27rem]">{text}</p>
    </div>
  );
};

export const ClientsCards = ({ icon, name, show }) => {
  return (
    <div
      className={`flex justify-center w-36 sm:w-44 md:w-28 lg:w-44 h-16 sm:h-24 md:h-16 lg:h-24      items-center rounded-2xl shadow-md px-4 py-6 hover:scale-110 transition ease-in-out ${
        show ? "hidden" : ""
      }`}
    >
      <img className="self-center w-[60%]" src={icon} alt={name} />
    </div>
  );
};

export const CaseStudiesPage = ({
  name,
  header1,
  header2,
  headerImg,
  about,
  services,
  launchLink,
  img1,
  img2,
  img3,
  img4,
  img5,
  previousLink,
  nextLink,
  width,
}) => {
  const variants = {
    initial: {
      scale: 0.8,
      opacity: 1,
      x: 100,
    },
    animate: {
      scale: 1,
      opacity: 1,
      x: 0,
      transition: {
        durations: 0.5,
      },
    },
  };

  const pictureVariants = {
    transition: {
      delayChildren: 0.4,
      staggerChildren: 0.5,
    },
  };

  return (
    <div className="px-4 sm:px-8 lg:px-16 flex flex-col items-center justify-center gap-16 sm:gap-32">
      <div className="flex flex-col gap-6">
        <div className="flex justify-between self-start w-full">
          <h4 className="text-[#A8A8A8] sm:text-xl lg:text-2xl self-start">
            {name}
          </h4>{" "}
          {width > 640 && (
            <LinksCard text={"View all works"} to={"/works"} width={width} />
          )}
        </div>
        <motion.h4
          variants={variants}
          initial="initial"
          animate="animate"
          className="text-2xl sm:text-3xl md:text-4xl lg:text-6xl font-medium sm:pb-10 lg:pb-14"
        >
          {header1}
          <br />
          {header2}
        </motion.h4>
        <div>
          <img src={headerImg} alt="" />
        </div>
      </div>
      <div className="flex flex-col gap-4 sm:w-3/5 sm:self-start">
        <h4 className="text-xl sm:text-2xl md:text-3xl lg:text-5xl font-semibold  ">
          About Project
        </h4>
        <p className="text-xl sm:text-2xl">{about}</p>
        <div>
          <h4 className="text-xl sm:text-2xl font-medium">
            Services Provided:
          </h4>
          <p className="text-xl sm:text-2xl">{services}</p>
        </div>
        <LinksCard text={"Launch Project"} to={launchLink} width={width} />
      </div>
      <section className="flex flex-col gap-12 sm:gap-16 lg:gap-20">
        <motion.div
          variants={pictureVariants}
          className="flex flex-col sm:flex-row gap-12 sm:gap-16 lg:gap-20"
        >
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.4, delay: 0.2 }}
            className="flex flex-col gap-12 sm:gap-16 lg:gap-20"
          >
            <div
            // className="h-72"
            >
              <img src={img1} alt="" />
            </div>
            <div
            //  className="h-[32rem]"
            >
              <img src={img3} alt="" />
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.4, delay: 0.2 }}
            className="flex flex-col gap-12 sm:gap-16 lg:gap-20"
          >
            <div
            //  className="h-[25rem]"
            >
              <img src={img2} alt="" />
            </div>
            <div
            // className="h-[26rem]"
            >
              <img src={img4} alt="" />
            </div>
          </motion.div>
        </motion.div>
        <div className=" ">
          {/* <img src={img5} alt="" /> */}
        </div>
      </section>
      <div className="flex justify-between w-full">
        {" "}
        <LinksCard
          text={"Previous case"}
          to={`/works/:${previousLink}`}
          width={width}
          arrow={true}
        />{" "}
        <LinksCard
          text={"Next case"}
          to={`/works/:${nextLink}`}
          width={width}
        />
      </div>
    </div>
  );
};

export const WorksLinks = ({ to, text }) => {
  const [active, setActive] = useState({
    all: true,
    webSite: false,
    mobile: false,
    webApp: false,
    admin: false,
  });

  const handleActive = (type) => {
    switch (type) {
      case "all":
        setActive({
          all: true,
          webSite: false,
          mobile: false,
          webApp: false,
          admin: false,
        });

        break;
      case "webSite":
        setActive({
          all: false,
          webSite: true,
          mobile: false,
          webApp: false,
          admin: false,
        });
        break;

      case "mobile":
        setActive({
          all: false,
          webSite: false,
          mobile: true,
          webApp: false,
          admin: false,
        });
        break;
      case "webApp":
        setActive({
          all: false,
          webSite: false,
          mobile: false,
          webApp: true,
          admin: false,
        });
        break;
      case "admin":
        setActive({
          all: false,
          webSite: false,
          mobile: false,
          webApp: false,
          admin: true,
        });
        break;

      default:
        setActive({
          all: true,
          webSite: false,
          mobile: false,
          webApp: false,
          admin: false,
        });
        break;
    }
  };
  const linksData = [
    {
      name: "All",
      to: "all",
    },
    {
      name: "WebSite",
      to: "webSite",
    },
    {
      name: "Mobile App",
      to: "mobile",
    },
    {
      name: "Web App",
      to: "webApp",
    },
    {
      name: "Admin Dashboard",
      to: "admin",
      hide: true,
    },
  ];
  const navVariant = {
    initial: {},
    animate: {
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  const hrVariant = {
    initial: {
      width: 0,
    },
    animate: {
      width: "100%",
      height: "1.5px",
      transition: {
        duration: 0.5,
      },
    },
  };
  return (
    <div className="flex gap-4">
      <ul className="flex gap-5 md:gap-10 text-xl md:text2xl self-center cursor-pointer">
        {linksData.map((item) => (
          <NavLink to="#" key={item.name}>
            <motion.div
              onClick={() => {
                handleActive(item.to);
              }}
              variants={navVariant}
              initial="initial"
              whileHover="animate"
            >
              <p
                className={`${
                  active[item.to] ? "text-black" : "text-[#A8A8A8]"
                } ${
                  item.hide ? "hidden sm:block" : ""
                } text-xl md:text-2xl cursor-pointer font-[500]`}
              >
                {item.name}
              </p>
              {!active[item.to] && (
                <motion.hr
                  variants={hrVariant}
                  className="bg-black m-0 border-0 h-[0.7px]"
                />
              )}
            </motion.div>
          </NavLink>
        ))}
      </ul>
    </div>
  );
};

export const LinksCard = ({ to, text, width, arrow }) => {
  const linkVariants = {
    animate: {
      x: 5,
      transition: {
        duration: 0.3,
        yoyo: Infinity,
        // repeatDelay: 0.3,
        ease: "easeInOut",
      },
    },
  };
  const navVariant = {
    initial: {},
    animate: {
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  const hrVariant = {
    initial: {
      width: 0,
    },
    animate: {
      width: "100%",
      transition: {
        duration: 0.5,
      },
    },
  };
  return (
    <Link to={to} className="flex items-center">
      {arrow && (
        <motion.span variants={linkVariants} animate="animate">
          {arrow && <MdKeyboardArrowLeft className="text-xl sm:text-2xl" />}
        </motion.span>
      )}
      <motion.div variants={navVariant} initial="initial" whileHover="animate">
        <p className="text-[#A8A8A8] sm:text-xl lg:text-2xl  ">{text}</p>
        {width > 640 && (
          <div className="relative">
            <hr className="bg-[#A8A8A8] m-0 border-0 h-[0.7px]" />
            <motion.hr
              variants={hrVariant}
              className="bg-black m-0 border-0 absolute top-0 h-[0.7px]"
            />
          </div>
        )}
      </motion.div>
      <motion.span variants={linkVariants} animate="animate">
        {!arrow && <MdKeyboardArrowRight className="text-xl sm:text-2xl" />}
      </motion.span>
    </Link>
  );
};
