import { NavLink, Link } from "react-router-dom";
import { navData } from "../DummyData";
import { AiOutlineMenu } from "react-icons/ai";
import Logo from "../assets/Logo.svg";
// import lightLogo from "../assets/lightLogo.svg";
import { useContext, useState } from "react";
import { IoMdClose } from "react-icons/io";
import widthContext from "../context/widthContext";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import DarkLogo from "./DarkLogo";
import LightLogo from "./LightLogo";

const NavBar = () => {
  const [menu, setMenu] = useState(false);
  const navigate = useNavigate();

  const width = useContext(widthContext);
  // console.log(menu)

  const handleMenu = () => {
    setMenu(!menu);
  };

  const container = {
    hidden: {
      opacity: 1,
      scale: 0,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const itemss = {
    hidden: {
      y: 20,
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        y: {
          stiffness: 1,
          velocity: -1000,
        },
      },
    },
  };

  const navVariant = {
    initial: {},
    animate: {
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  const hrVariant = {
    initial: {
      width: 0,
    },
    animate: {
      width: "100%",
      transition: {
        duration: 0.5,
      },
    },
  };

  const NavBarLink = ({ name, to }) => {
    return (
      <li>
        <NavLink to={to}>
          {({ isActive }) => (
            <motion.div
              variants={navVariant}
              initial="initial"
              whileHover="animate"
              className=""
            >
              <p className=" ">{name}</p>
              <div className=" relative ">
                <motion.hr
                  variants={hrVariant}
                  className="bg-black m-0 border-0 h-[0.7px]"
                />

                {isActive && (
                  <hr className="bg-black m-0 border-0 h-[0.7px] absolute w-full bottom-0  z-50  " />
                )}
              </div>
            </motion.div>
          )}
        </NavLink>
      </li>
    );
  };

  const webNav = (
    <nav className=" flex items-center justify-between px-4 sm:px-8 lg:px-16 py-8 backdrop-blur-sm bg-white/30">
      <NavLink to="home">
        {/* <img src={animatedDarkLogo} alt="Logo" /> */}
        <DarkLogo />
      </NavLink>
      <div className="flex gap-4">
        <ul className="flex gap-4">
          {navData.map((item) => (
            <NavBarLink name={item.name} to={item.to} key={item.to} />
          ))}
        </ul>
        <NavLink className="flex items-center gap-2  " to="/more">
          More
          <AiOutlineMenu />
        </NavLink>
      </div>
    </nav>
  );

  const mobileNav = !menu ? (
    <nav className="flex justify-between px-4 py-8">
      <div
        onClick={() => {
          navigate("/home");
        }}
      >
        <img src={Logo} alt="logo" />
      </div>
      <AiOutlineMenu onClick={() => handleMenu()} />
    </nav>
  ) : (
    <motion.nav
      variants={container}
      initial="hidden"
      animate="visible"
      className="bg-black flex flex-col gap-12 px-4 overflow-y-hidden items-center  fixed z-20 top-0 w-[100vw] h-[100vh]"
    >
      <motion.div
        variants={itemss}
        className=" w-full flex justify-between  py-8"
      >
        <Link
          to="/home"
          onClick={() => {
            handleMenu();
          }}
        >
          {<LightLogo />}
        </Link>

        <div>
          <IoMdClose onClick={handleMenu} className="text-white" />
        </div>
      </motion.div>
      <div className="flex flex-col gap-12">
        <motion.ul
          variants={container}
          initial="hidden"
          animate="visible"
          className="flex flex-col gap-8 justify-center items-center"
        >
          {navData.map((item) => (
            <NavLink key={item.to} to={item.to} onClick={handleMenu}>
              <motion.p
                variants={itemss}
                whileTap={{ scale: 0.95 }}
                className="text-3xl text-[#E5E5E5] border-b border-[#585858]"
              >
                {item.name}
              </motion.p>
              {/* <hr /> */}
            </NavLink>
          ))}
        </motion.ul>
        <p className="text-[#9C9C9C] text-3xl text-center">
          Learn more{" "}
          <Link className="text-white" onClick={handleMenu} to="/services">
            about us,{" "}
          </Link>
          get the latest on{" "}
          <Link to="#" onClick={handleMenu} className="text-white">
            instagram{" "}
          </Link>
          or{" "}
          <Link className="text-white" to="/contact" onClick={handleMenu}>
            contact us{" "}
          </Link>
        </p>
      </div>
      <NavLink
        className="text-[#9C9C9C] text-center text-2xl border-b border-[#9C9C9C] "
        to="/home"
        onClick={handleMenu}
      >
        Home
      </NavLink>
    </motion.nav>
  );

  return width > 640 ? webNav : mobileNav;
};

export default NavBar;
