import { ServicesCard, ClientsCards } from "../../components/Cards";
import { servicesData } from "../../DummyData";
import { MdKeyboardArrowRight } from "react-icons/md";
import services from "../../assets/services/services.svg";
import { clientsData } from "../../DummyData";
import { useContext, useRef, useState } from "react";
import widthContext from "../../context/widthContext";
import { motion, useInView } from "framer-motion";
import CountUp from "react-countup";

const Services = () => {
  const [review, setreview] = useState(1);
  const width = useContext(widthContext);
  const dataRef = useRef(null);
  const dataInView = useInView(dataRef);

  let slideIndex = review;
  const slideShow = (n) => {
    let testimony = document.getElementsByClassName("home");

    if (n > testimony.length) {
      // slideIndex = 1;
      slideIndex = 1;
      // setreview(1);
    }
    if (n <= 4) {
      slideIndex++;
    }
    for (let i = 0; i < testimony.length; i++) {
      testimony[i].classList.add("hidden");
    }
    testimony[slideIndex - 1].classList.remove("hidden");

    setreview(slideIndex);
  };

  const plusSlides = () => {
    slideShow(slideIndex + 1);
  };

  const variants = {
    initial: {
      scale: 0.8,
      opacity: 0,
      x: 100,
    },
    animate: {
      scale: 1,
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.5,
        when: "beforeChildren",
      },
    },
  };
  const navVariant = {
    initial: {},
    animate: {
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  const hrVariant = {
    initial: {
      width: 0,
    },
    animate: {
      width: "100%",
      transition: {
        duration: 0.5,
      },
    },
  };
  return (
    <main className="px-4 sm:px-8 lg:px-16 flex flex-col   gap-16 sm:gap-32">
      <motion.h3
        variants={variants}
        initial="initial"
        animate="animate"
        className=" self-start font-semibold text-2xl sm:text-3xl md:text-4xl lg:text-6xl"
      >
        We shape the products and services <br /> that help businesses excel.
      </motion.h3>
      <div className="flex flex-col self-start gap-4 sm:pl-8 md:pl-16 lg:pl-32">
        <h4 className="text-xl sm:text-2xl md:text-3xl lg:text-5xl font-semibold ">
          Proactiveness <br />
          meets tech
        </h4>
        <p className="text-xl md:text-2xl sm:w-[50vw] sm:pl-6 md:pl-10 lg:p ">
          Founded in 2007, Protech Advance has been committed to delivering
          efficient solutions for all our clients. With a distinctive flair for
          excellence we have delivered unique solutions for our partners over
          the years with a proven track record for deploying efficient software
          and hardware tailored to fit each client’s unique needs.
        </p>
      </div>
      <section className="flex flex-col gap-16 sm:gap-32">
        <div className="flex flex-col sm:flex-row sm:items-center gap-16 sm:px-12">
          <div className="w-full">
            <img src={services} className="md:h-[400px]" alt="computer" />
          </div>
          <div className="text-xl">
            <p className="md:text-2xl">
              <span className="font-semibold "> Our Mission — </span>
              Creating simple, dedicated design and development solutions
              tailored to meet specific needs and providing efficient technology
              solutions to boost productivity.
            </p>
          </div>
        </div>
        <div ref={dataRef} className="self-center">
          {dataInView && (
            <ul className="flex flex-col sm:flex-row gap-16 justify-center">
              <li className="flex flex-col gap-4">
                <CountUp start={0} end={2007} delay={0} duration={3}>
                  {({ countUpRef }) => (
                    <p className="text-6xl" ref={countUpRef} />
                  )}
                </CountUp>
                <p className="text-2xl">Founded</p>
              </li>
              <li className="flex flex-col gap-4">
                {/* <h4 className="text-6xl">200+</h4> */}
                <CountUp
                  start={0}
                  end={200}
                  delay={0}
                  duration={3}
                  suffix={"+"}
                >
                  {({ countUpRef }) => (
                    <p className="text-6xl" ref={countUpRef} />
                  )}
                </CountUp>
                <p className="text-2xl">Completed Projects</p>
              </li>
              <li className="flex flex-col gap-4">
                {/* <h4 className="text-6xl">50+</h4> */}
                <CountUp start={0} end={50} duration={3} delay={0} suffix={"+"}>
                  {({ countUpRef }) => (
                    <p className="text-6xl " ref={countUpRef} />
                  )}
                </CountUp>
                <p className="text-2xl">Happy Clients</p>
              </li>
            </ul>
          )}
        </div>
      </section>
      <section className="bg-black text-white w-full px-8 sm:p-16 md:p-24 py-14 ">
        <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-semibold pb-9">
          Our Services
        </h2>
        <div className="flex flex-col sm:px-20 lg:px-40 xl:px-52   ">
          {servicesData.map((item) => (
            <ServicesCard
              key={item.text}
              icon={item.icon}
              header1={item.header1}
              header2={item.header2}
              text={item.text}
              border={item.border}
            />
          ))}
        </div>
      </section>
      <section className=" flex flex-col sm:px-20 md:px-40 lg:px-52 items-center ">
        <h4 className="self-start font-semibold text-2xl sm:text-3xl md:text-4xl lg:text-5xl  pb-8 md:pb-16">
          Clients we serve
        </h4>
        {width < 640 ? (
          <div className="grid grid-cols-2 gap-10">
            {clientsData.map((item) => (
              <ClientsCards
                key={item.name}
                icon={item.icon}
                name={item.name}
                show={item.show}
              />
            ))}
          </div>
        ) : (
          <div className="flex gap-8 items-center">
            <div className="flex flex-col gap-4">
              {clientsData.map((item, index) => {
                if (index < 2) {
                  return (
                    <ClientsCards
                      key={item.name}
                      icon={item.icon}
                      name={item.name}
                    />
                  );
                } else {
                  return null;
                }
              })}
            </div>
            <div className="flex flex-col gap-4">
              {clientsData.map((item, index) => {
                if (index > 1 && index < 5) {
                  return (
                    <ClientsCards
                      key={item.name}
                      icon={item.icon}
                      name={item.name}
                    />
                  );
                } else {
                  return null;
                }
              })}
            </div>
            <div className="flex flex-col gap-4 -translate-y-8">
              {clientsData.map((item, index) => {
                if (index > 4 && index < 8) {
                  return (
                    <ClientsCards
                      key={item.name}
                      icon={item.icon}
                      name={item.name}
                    />
                  );
                } else {
                  return null;
                }
              })}
            </div>
            <div className="flex flex-col gap-4">
              {clientsData.map((item, index) => {
                if (index > 7 && index < 11) {
                  return (
                    <ClientsCards
                      key={item.name}
                      icon={item.icon}
                      name={item.name}
                    />
                  );
                } else {
                  return null;
                }
              })}
            </div>
            <div className="flex flex-col gap-4">
              {clientsData.map((item, index) => {
                if (index > 10 && index < 13) {
                  return (
                    <ClientsCards
                      key={item.name}
                      icon={item.icon}
                      name={item.name}
                    />
                  );
                } else {
                  return null;
                }
              })}
            </div>
          </div>
        )}
      </section>
      <section className="flex flex-col gap-8 sm:px-20 md:px-40 lg:px-52 ">
        {/* flex flex-col sm:px-20 md:px-40 lg:px-52 items-center  */}
        <h3 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-semibold">
          Feedback <br />
          from our clients
        </h3>
        <div>
          <div className="self-center sm:w-[50vw] home">
            <h4 className="text-[#A8A8A8] text-2xl pb-4">SellBitFast —</h4>
            <p className="text-2xl   ">
              Protech proved to be a reliable and responsible partner that we
              were comfortable collaborating with.
            </p>
          </div>

          <div className="self-center sm:w-[50vw] home hidden">
            <h4 className="text-[#A8A8A8] text-2xl pb-4">TredKg Lifters —</h4>
            <p className="text-2xl   ">
              Protech proved to be a reliable and responsible partner that we
              were comfortable collaborating with.
            </p>
          </div>

          <div className="self-center sm:w-[50vw] home hidden">
            <h4 className="text-[#A8A8A8] text-2xl pb-4">MyReward —</h4>
            <p className="text-2xl   ">
              Protech proved to be a reliable and responsible partner that we
              were comfortable collaborating with.
            </p>
          </div>

          <div className="self-center sm:w-[50vw] home hidden">
            <h4 className="text-[#A8A8A8] text-2xl pb-4"> D4U Logistics —</h4>
            <p className="text-2xl   ">
              Protech proved to be a reliable and responsible partner that we
              were comfortable collaborating with.
            </p>
          </div>
        </div>
        <button
          className="flex gap-2 items-center self-center"
          onClick={() => {
            plusSlides();
          }}
        >
          <motion.div
            variants={navVariant}
            initial="initial"
            whileHover="animate"
          >
            <p className="text-[#A8A8A8] sm:text-2xl">View next</p>
            {width > 640 && (
              <div className="relative">
                <hr className="bg-[#A8A8A8] m-0 border-0 h-[0.7px]" />
                <motion.hr
                  variants={hrVariant}
                  className="bg-black m-0 border-0 absolute top-0 h-[0.7px]"
                />
              </div>
            )}
          </motion.div>

          <MdKeyboardArrowRight />
        </button>
      </section>
    </main>
  );
};

export default Services;
