import { motion } from "framer-motion";
import { useState } from "react";
import SuccessModal from "./SuccessModal";
import axios from "axios";

const Contact = () => {
  const [success, setSuccess] = useState(false);
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    details: "",
  });

  const formHandler = async (e) => {
    e.preventDefault();
    setShow(true);

    try {
      const response = await axios({
        method: "post",
        url: "https://xpro.getshopeasy.com/api/v1/send-email",
        data: {
          full_name: formData.name,
          email_address: formData.email,
          message_content: formData.details,
        },
        headers: {},
      });

      if (response.status === 200) {
        setSuccess(true);
        setFormData({
          name: "",
          email: "",
          details: "",
        });
        document.contactForm.reset();
      }
    } catch (err) {
      let message = err.response.data.message.split(".")
      setError(message[0]);
    }
  };

  const nameHandler = (e) => {
    setFormData({
      ...formData,
      name: e.target.value,
    });
  };

  const emailHandler = (e) => {
    setFormData({
      ...formData,
      email: e.target.value,
    });
  };

  const projectHandler = (e) => {
    setFormData({
      ...formData,
      details: e.target.value,
    });
  };
  const variants = {
    initial: {
      scale: 0.8,
      opacity: 0,
      x: 100,
    },
    animate: {
      scale: 1,
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.5,
      },
    },
  };
  return (
    <main className="flex flex-col  px-4  sm:px-8 lg:px-16 gap-16">
      <div className="flex flex-col gap-8 pb-16">
        <motion.h4
          variants={variants}
          initial="initial"
          animate="animate"
          className="text-2xl sm:text-3xl md:text-4xl lg:text-6xl"
        >
          Let us know
          <br /> what you need
        </motion.h4>
        <p className="text-xl sm:text-2xl sm:w-1/2">
          Please fill in your details and needs. Someone will get in touch with
          24hours.
        </p>
      </div>
      <section className="flex flex-col sm:flex-row gap-40 sm:gap-0 sm:justify-between w-full">
        <form
          action=""
          name="contactForm"
          onSubmit={formHandler}
          className="flex flex-col gap-8 sm:gap-12 sm:w-[40%]"
        >
          <div className="flex flex-col gap-5 w-">
            <label
              className="text-[#A8A8A8] text-xl sm:text-2xl"
              htmlFor="name"
            >
              Your name
            </label>
            <input
              className="border-b border-[#A8A8A8] outline-none h-4"
              type="text"
              onChange={nameHandler}
            />
          </div>
          <div className="flex flex-col gap-5 ">
            <label
              className="text-[#A8A8A8] text-xl sm:text-2xl"
              htmlFor="email"
            >
              Your email
            </label>
            <input
              className="border-b border-[#A8A8A8] outline-none h-4"
              type="email"
              onChange={emailHandler}
            />
          </div>
          <div className="flex flex-col gap-5">
            <label
              className="text-[#A8A8A8] text-xl sm:text-2xl outline-none "
              htmlFor="project"
            >
              Tell us about your project
            </label>
            <textarea
              className="border-b border-[#A8A8A8] outline-none"
              name="project"
              id=""
              cols="30"
              rows="1"
              onChange={projectHandler}
            />
          </div>
          <button
            // disabled={!validForm}
            className="py-5 px-8 rounded-[40px] bg-black text-white w-fit self-center sm:self-start hover:scale-105 transition ease-in-out"
          >
            Send request
          </button>
        </form>
        <div>
          <h4 className="text-2xl font-semibold pb-7">Contacts</h4>
          <div className="text-2xl flex flex-col gap-6">
            <p>E:hello@protechadvance.com</p>
            <p>T:hello@protechadvance.com</p>
            <p>
              Elpina Plaza,
              <br /> 642 Akin Adesola Street,
              <br /> Victoria Island, Lagos, Nigeria
            </p>
          </div>
        </div>
      </section>

      {/* {modal && */}
      <SuccessModal
        success={success}
        onClose={() => {
          setShow(false);
          setError("");
          setSuccess(false);
        }}
        show={show}
        error={error}
      />
      {/* } */}
    </main>
  );
};

export default Contact;
