import "./App.css";
import { Routes, Route, useNavigate } from "react-router-dom";
import {
  LandingPage,
  Contact,
  JoinUs,
  Products,
  Services,
  Work,
  More,
  CaseStudies,
} from "./pages";
import Layout from "./layout/Layout";
import widthContext from "./context/widthContext";
import { useEffect, useState } from "react";
import ScrollToTop from "./components/ScrollToTop";

const NotFoundPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/");
  }, []);
  return;
};

function App() {
  const [width, setWidth] = useState(window.innerWidth);
  const handleResize = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  return (
    <div className="App">
      <widthContext.Provider value={width}>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route path="/" index element={<LandingPage />} />
              <Route path="/works" element={<Work />} />
              <Route path="/works/:workId/*" element={<CaseStudies />} />
              <Route path="/services" element={<Services />} />
              <Route path="/products" element={<Products />} />
              <Route path="/join" element={<JoinUs />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/more" element={<More />} />
              <Route path="*" element={<NotFoundPage />} />
            </Route>
          </Routes>
        </ScrollToTop>
      </widthContext.Provider>
    </div>
  );
}

export default App;
