// recent works imports
// import myReward from "./assets/works/myReward.svg";
// import tredKg from "./assets/works/tredKg.svg";
// import d4u from "./assets/works/d4u.svg";
import d4u from "./assets/works/d4u/d4u.png";
import sellbitfast from "./assets/works/sellbit.svg";

// services imports
import trophy from "./assets/services/trophy.svg";
import laptop from "./assets/services/laptop.svg";
import cube from "./assets/services/cube.svg";

//  clients data imports
import agro from "./assets/svg/agro.svg";
import brighterlife from "./assets/svg/brighterlife.svg";
import centerbase from "./assets/svg/centerbase.svg";
import code54 from "./assets/svg/code54.svg";
import cubeLog from "./assets/svg/cubeLog.svg";
import easy from "./assets/svg/easy.svg";
import fitfam from "./assets/svg/fitfam.svg";
import haul from "./assets/svg/haul.svg";
import life from "./assets/svg/life.svg";
import mamacass from "./assets/svg/mamacass.svg";
import realty from "./assets/svg/realty.svg";
import truckam from "./assets/svg/truckam.svg";

// case studies data import
// RUN NG
import runHeader from "./assets/works/run-ng/runheader.png";
import run1 from "./assets/works/run-ng/run1.png";
import run2 from "./assets/works/run-ng/run2.png";
import run3 from "./assets/works/run-ng/run3.png";
import run4 from "./assets/works/run-ng/run4.png";

// AgroLink
import agroHeader from "./assets/works/agro-link/agroheader.png";
import agro1 from "./assets/works/agro-link/agro1.png";
import agro2 from "./assets/works/agro-link/agro2.png";
import agro3 from "./assets/works/agro-link/agro3.png";
import agro4 from "./assets/works/agro-link/agro4.png";

// D4U Logistics
import d4uHeader from "./assets/works/d4u/d4uheader.png";
import d4u1 from "./assets/works/d4u/d4u1.png";
import d4u2 from "./assets/works/d4u/d4u2.png";
import d4u3 from "./assets/works/d4u/d4u3.png";
import d4u4 from "./assets/works/d4u/d4u4.png";

// ESAN Finance
import esanHeader from "./assets/works/esan/esanheader.png";
import esan1 from "./assets/works/esan/esan1.png";
import esan2 from "./assets/works/esan/esan2.png";
import esan3 from "./assets/works/esan/esan3.png";
import esan4 from "./assets/works/esan/esan4.png";

// BedSpace
import bedHeader from "./assets/works/bed/bedheader.png";
import bed1 from "./assets/works/bed/bed1.png";
import bed2 from "./assets/works/bed/bed2.png";
import bed3 from "./assets/works/bed/bed3.png";
import bed4 from "./assets/works/bed/bed4.png";

// GiftCardsToNaira
import giftcardsHeader from "./assets/works/giftcards/giftcardsheader.png";
import giftcards1 from "./assets/works/giftcards/giftcards1.png";
import giftcards2 from "./assets/works/giftcards/giftcards2.png";
import giftcards3 from "./assets/works/giftcards/giftcards3.png";
import giftcards4 from "./assets/works/giftcards/giftcards4.png";

// BlackJoe
import blackjoeHeader from "./assets/works/blackjoe/blackjoeheader.png";
import blackjoe1 from "./assets/works/blackjoe/blackjoe1.png";
import blackjoe2 from "./assets/works/blackjoe/blackjoe2.png";
import blackjoe3 from "./assets/works/blackjoe/blackjoe3.png";
import blackjoe4 from "./assets/works/blackjoe/blackjoe4.png";

// Gasmonkey
import gasmonkeyHeader from "./assets/works/gasmonkey/gasmonkeyheader.png";
import gasmonkey1 from "./assets/works/gasmonkey/gasmonkey1.png";
import gasmonkey2 from "./assets/works/gasmonkey/gasmonkey2.png";
import gasmonkey3 from "./assets/works/gasmonkey/gasmonkey3.png";
import gasmonkey4 from "./assets/works/gasmonkey/gasmonkey4.png";

// Shop4me
import shop4meHeader from "./assets/works/shop4me/shop4meheader.png";
import shop4me1 from "./assets/works/shop4me/shop4me1.png";
import shop4me2 from "./assets/works/shop4me/shop4me2.png";
import shop4me3 from "./assets/works/shop4me/shop4me3.png";
import shop4me4 from "./assets/works/shop4me/shop4me4.png";

// Currenxi
import currenxiHeader from "./assets/works/currenxi/currenxiheader.png";
import currenxi1 from "./assets/works/currenxi/currenxi1.png";
import currenxi2 from "./assets/works/currenxi/currenxi2.png";
import currenxi3 from "./assets/works/currenxi/currenxi3.png";
import currenxi4 from "./assets/works/currenxi/currenxi4.png";

// NavBar Data
export const navData = [
  { name: "Work", to: "works" },
  { name: "Services", to: "services" },
  { name: "Products", to: "products" },
  { name: "Join Us", to: "join" },
];

// recent works data

export const recentWorksData = [
  // {
  //   img: myReward,
  //   alt: "myReward app",
  //   header: "MyReward",
  //   headerColor: "text-white",
  //   text: "UI/UX, Mobile App Development",
  //   textColor: "text-[#E0E0E0]",
  //   bg: "bg-[#F6621D]",
  //   reward: true,
  // },

  {
    // img: run,
    alt: "runng",
    header: "RUN NG",
    headerColor: "text-white",
    text: "UI/UX, Mobile App Development",
    textColor: "text-[#E0E0E0]",
    bg: "bg-run",
  },
  {
    img: d4u,
    alt: "d4ulogistics",
    header: "D4U Logistics",
    headerColor: "text-black",
    text: "UI/UX, Mobile App Development",
    textColor: "text-[#565656]",
    bg: "bg-[#FFCD06]",
  },

  {
    header: "Agrolink",
    alt: "agrolink",
    headerColor: "text-white",
    text: "UI/UX, Mobile App Development",
    textColor: "text-[#E0E0E0]",
    bg: "bg-agro",
  },
  {
    header: "Esan Finance",
    alt: "esanFinance",
    headerColor: "text-white",
    text: "UI/UX, Mobile App Development",
    textColor: "text-[#E0E0E0]",
    bg: "bg-esan",
  },
  {
    header: "BedSpace",
    alt: "bedspace",
    headerColor: "text-black",
    text: "UI/UX, Mobile App Development",
    textColor: "text-[#565656]",
    bg: "bg-bed",
  },
  {
    header: "GiftCardsToNaira",
    alt: "giftcardstonaira",
    headerColor: "text-white",
    text: "UI/UX, Mobile App Development",
    textColor: "text-[#E0E0E0]",
    bg: "bg-giftcards",
  },
  {
    header: "BlackJoe Foods",
    alt: "blackjoe",
    headerColor: "text-white",
    text: "UI/UX, Mobile App Development",
    textColor: "text-[#E0E0E0]",
    bg: "bg-blackjoe",
  },
  {
    header: "Gasmonkey",
    alt: "gasmonkey",
    headerColor: "text-white",
    text: "UI/UX, Mobile App Development",
    textColor: "text-[#E0E0E0]",
    bg: "bg-gasmonkey",
  },
  {
    header: "Shop4Me",
    alt: "shop4me",
    headerColor: "text-white",
    text: "UI/UX, Mobile App Development",
    textColor: "text-[#E0E0E0]",
    bg: "bg-shop4me",
  },
  {
    header: "Currenxi",
    alt: "currenxi",
    headerColor: "text-white",
    text: "UI/UX, Web & Admin Development",
    textColor: "text-[#E0E0E0]",
    bg: "bg-currenxi",
  },
];

// industry sector experience

export const industryData = [
  "Consumer goods",
  "Culture and education",
  "Financial services",
  "Fintech",
  "Retail",
  "Professional services",
  "Food and drink",
  "Health",
  "Logistics",
  "Non-profit",
  "Travel and hospitality",
  "E-commerce",
  // "Professional services"
];

// services data

// icon, header1, header2, text

export const servicesData = [
  {
    icon: trophy,
    header1: "UI/UX",
    header2: "Design",
    text: "We are passionate about creating effective and efficient human-computer interactions via universal design principles, and usability of best practices.",
    border: true,
  },
  {
    icon: laptop,
    header1: "Mobile App",
    header2: "Development",
    text: "We develop immersive mobile experiences across all platforms with reliable and efficient applications designed to boost productivity.",
  },
  {
    icon: cube,
    header1: "Web",
    header2: "Development",
    text: "The internet is the number 1 global resource for information. We create very modern and inspiring web experiences for all sectors and business types.",
  },
];

export const clientsData = [
  {
    icon: agro,
    name: "agro",
  },
  {
    icon: brighterlife,
    name: "brighterlife",
  },
  {
    icon: centerbase,
    name: "centerbase",
  },

  {
    icon: cubeLog,
    name: "cubeLog",
  },
  {
    icon: easy,
    name: "easy",
  },
  {
    icon: fitfam,
    name: "fitfam",
  },
  {
    icon: haul,
    name: "haul",
  },
  {
    icon: life,
    name: "life",
  },
  {
    icon: mamacass,
    name: "mamacass",
  },
  {
    icon: realty,
    name: "realty",
  },
  {
    icon: truckam,
    name: "truckam",
  },
  {
    icon: code54,
    name: "code54",
  },
  {
    icon: agro,
    name: "agro2",
    show: true,
  },
];

// case studies data

export const caseStudiesData = [
  // runng: "runng",
  // agrolink: "agrolink",
  // d4ulogistics: "d4ulogistics",
  // esanFinance: "esanFinance",
  // bedspace: "bedspace",
  // giftcardstonaira: "giftcardstonaira",
  // blackjoe: "blackjoe",
  // gasmonkey: "gasmonkey",
  // shop4me: "shop4me",
  // currenxi: "currenxi",
  {
    title: "Run Ng — ",
    name: "runng",
    header1: "Online platform  ",
    header2: " for ordering a ride.",
    headerImg: runHeader,
    about: `Run Ng is here to help you get where you need to be in a hurry.
     We understand that sometimes the last thing you want to do is drive
      yourself—so we can offer transportation on your terms. Whether it's
       getting to work on time or helping an elderly family member get around town, we've got you covered.
    `,
    services: "UI/UX, Mobile App Development",
    launchLink: "/products",
    img1: run1,
    img2: run2,
    img3: run3,
    img4: run4,
    prev: "currenxi",
    next: "agrolink",
  },
  {
    title: "Agrolink — ",
    name: "agrolink",
    header1: "Online platform  ",
    header2: " for ordering fresh farm produce.",
    headerImg: agroHeader,
    about: `We're not just a shop—we're a fresh, agriculture-based 
    farm that delivers fresh farm produce to your door. With our tech
     and phone apps, we allow you to order food from us as easily as
      ordering something off Amazon. And with our comfort-ease-fresh
       food delivery service, you can get fresh veggies and other healthy 
       ingredients delivered straight to your door!

    You'll never have to worry about finding the time to shop again—just sit back while we do it for you! `,
    services: "UI/UX, Mobile App Development",
    launchLink: "/products",
    img1: agro1,
    img2: agro2,
    img3: agro3,
    img4: agro4,
    prev: "runng",
    next: "d4ulogistics",
  },
  {
    title: "D4U Logistics — ",
    name: "d4ulogistics",
    header1: "Online platform  ",
    header2: " fo logistic services.",
    headerImg: d4uHeader,
    about: `D4U Logistics can help you get your packages shipped on time. We understand that logistics is a big deal, 
    and we're here to help you get it done quickly and correctly. 

    We've been in business for over 20 years, so we know what we're doing! 
    And with our expertise in logistics, tech, phone, order fulfillment and delivery services, 
    we're ready to take on any project—big or small.
    
    We know that time is money—and that's why we'll work hard to get your product out the door as quickly as possible. 
    We have experience in helping businesses meet deadlines and keep their clients happy at the same time! `,
    services: "UI/UX, Mobile App Development",
    launchLink: "/products",
    img1: d4u1,
    img2: d4u2,
    img3: d4u3,
    img4: d4u4,
    prev: "agrolink",
    next: "esanFinance",
  },
  {
    title: "Esan Finance — ",
    name: "esanFinance",
    header1: "Online platform  ",
    header2: " for E-Banking.",
    headerImg: esanHeader,
    about: `You’re probably thinking that you don’t need all these new services, but let me tell you: they’re absolutely necessary.

    Banking is a lot easier today than it was even five years ago. You can do everything from banking online to paying bills
     and managing your money right from your phone.
     This is because of fintech, which gives you access to tools that were once only available in brick-and-mortar locations.
     If you want to keep track of your balances and transactions—or just want to know how much money is in your account—Esan Financials is here for you!
      It will allow you to do all kinds of things with your money like manage expenses or set
       up autopayments on credit cards or loans.
     `,
    services: "UI/UX, Mobile App Development",
    launchLink: "/products",
    img1: esan1,
    img2: esan2,
    img3: esan3,
    img4: esan4,
    prev: "d4ulogistics",
    next: "bedspace",
  },
  {
    title: "BedSpace — ",
    name: "bedspace",
    header1: "Online platform  ",
    header2: " for renting apartments.",
    headerImg: bedHeader,
    about: `Are you looking for a bed space, room, or short-stay rental? We've got you covered!
    BedSpace is the perfect choice if you're looking for something quick and easy.
     We can help you find an affordable place to stay in your area. Our team will
      work closely with you to make sure that the experience is one that will feel like home
       and help you get there in style. `,
    services: "UI/UX, Mobile App Development",
    launchLink: "/products",
    img1: bed1,
    img2: bed2,
    img3: bed3,
    img4: bed4,
    prev: "esanFinance",
    next: "giftcardstonaira",
  },
  {
    title: "GiftCardsToNaira — ",
    name: "giftcardstonaira",
    header1: "Online platform  ",
    header2: " for trading giftcards.",
    headerImg: giftcardsHeader,
    about: `Hey, we're GiftCardsToNaira!

    We're the company that helps you turn your credit card rewards into cash.
    
    We'll help you trade your gift cards for cash in just a few minutes. 
    And we'll give you quick cash so you can buy something nice for yourself or someone else—or use it to help someone else.
    
    All our transactions are instant and safe. And we never ask for
     more than 3% of the value of your card. So don't worry about getting scammed!`,
    services: "UI/UX, Mobile App Development",
    launchLink: "/products",
    img1: giftcards1,
    img2: giftcards2,
    img3: giftcards3,
    img4: giftcards4,
    prev: "bedspace",
    next: "blackjoe",
  },
  {
    title: "BlackJoe Food — ",
    name: "blackjoe",
    header1: "Online platform  ",
    header2: " for order food.",
    headerImg: blackjoeHeader,
    about: ` We know that getting food delivered to your door can be a hassle—but that's why we're here to make it easy.
    Order from home, phone, or web, and we'll take care of the rest.
     We'll even let you set up an easy payment plan so that you don't have to stress over the money. `,
    services: "UI/UX, Mobile App Development",
    launchLink: "/products",
    img1: blackjoe1,
    img2: blackjoe2,
    img3: blackjoe3,
    img4: blackjoe4,
    prev: "giftcardstonaira",
    next: "gasmonkey",
  },
  {
    title: "Gasmonkey — ",
    name: "gasmonkey",
    header1: "Online platform  ",
    header2: " for buying gas.",
    headerImg: gasmonkeyHeader,
    about: `If you're looking for the ultimate in convenience, look no further than Gas Monkey.
    We offer a wide range of services that make it easy to buy cooking gas online,
     refill your cooking gas easily, and be comfortable in your home. You can pay with a phone,
      and our delivery team will bring the gas to you. `,
    services: "UI/UX, Mobile App Development",
    launchLink: "/products",
    img1: gasmonkey1,
    img2: gasmonkey2,
    img3: gasmonkey3,
    img4: gasmonkey4,
    prev: "blackjoe",
    next: "shop4me",
  },
  {
    title: "Shop4Me — ",
    name: "shop4me",
    header1: "Online platform  ",
    header2: " for E-Shopping.",
    headerImg: shop4meHeader,
    about: `At Shop4Me, we believe that shopping should be easy, and we're here to help you make it that way.

    Our online store is simple, easy-to-use, and designed for your convenience. We understand that not everyone can shop online—that's
     why we offer a host of delivery options to 
    make sure you get the items you want fast! Make sure you check out our delivery options as soon
     as possible—they're sure to impress even the most demanding customers. `,
    services: "UI/UX, Mobile App Development",
    launchLink: "/products",
    img1: shop4me1,
    img2: shop4me2,
    img3: shop4me3,
    img4: shop4me4,
    prev: "gasmonkey",
    next: "currenxi",
  },
  {
    title: "Currenxi — ",
    name: "currenxi",
    header1: "Online platform  ",
    header2: " for digital trading.",
    headerImg: currenxiHeader,
    about: `Buying crypto coins is easy and convenient.

    You can trade with the comfort of your own home, on your phone or computer. 
    Just make an account and start trading! Also, the platform is secure, quick transactions,
     and you can easily get your balance to know how much money you have.`,
    services: "UI/UX, Mobile App Development",
    launchLink: "/products",
    img1: currenxi1,
    img2: currenxi2,
    img3: currenxi3,
    img4: currenxi4,
    prev: "shop4me",
    next: "runng",
  },
];
