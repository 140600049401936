import { RecentWorksCard, LinksCard } from "../../components/Cards";
import { recentWorksData } from "../../DummyData";
import { useContext } from "react";
import widthContext from "../../context/widthContext";
import { motion, useInView, useAnimationControls } from "framer-motion";
import { useEffect, useRef } from "react";

const LandingPage = () => {
  const width = useContext(widthContext);
  const recentWorksRef = useRef(null);
  // const recentWorksRef2 = useRef(null);
  const animationControl = useAnimationControls();

  const inView = useInView(recentWorksRef, { once: false });

  useEffect(() => {
    if (inView) {
      animationControl.start({
        opacity: 1,
        y: 0,
        transition: { delayChildren: 0.5, staggerChildren: 0.5, duration: 1 },
      });
    }
  }, [inView, animationControl]);

  const recentWorksVariants = {
    workStart: {
      opacity: 0,
      y: 300,
    },
    workEnd: {
      opacity: 1,
      y: 0,
      transition: { delayChildren: 2, staggerChildren: 1, duration: 1 },
    },
  };

  const variants = {
    initial: {
      scale: 0.8,
      opacity: 0,
      x: 100,
    },
    animate: {
      scale: 1,
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <div className="px-4 sm:px-8 lg:px-16 flex flex-col items-center gap-16 sm:gap-32 ">
      <section className="flex flex-col sm:text-center sm:items-center gap-4 sm:gap-12 sm:px-24 lg:px-32 sm:py-20 lg:py-28  sm:bg-[#F3F3F3] ">
        <motion.h3
          variants={variants}
          initial="initial"
          animate="animate"
          className="text-3xl font-semibold md:text-5xl lg:text-6xl"
        >
          We help create transformative products that move businesses, people
          and the world forward
          {/* <Dots/> */}
          {/* <Waves backgroundColor="" waveColor="gray" text="lllllll" /> */}
        </motion.h3>

        <LinksCard
          to={"/contact"}
          text="Let's create something together"
          width={width}
        />
      </section>
      <section className="w-full lg:px-16 flex flex-col justify-center gap-4  ">
        <h4 className="font-semibold text-xl ">Recent works</h4>
        {width < 640 ? (
          <motion.div
            variants={recentWorksVariants}
            initial="workStart"
            animate={animationControl}
            ref={recentWorksRef}
            className="flex flex-col gap-6   "
          >
            {recentWorksData.map((item, index) => (
              <div>
                <RecentWorksCard
                  key={item.alt}
                  header={item.header}
                  headerColor={item.headerColor}
                  text={item.text}
                  textColor={item.textColor}
                  bg={item.bg}
                  img={item.img}
                  alt={item.alt}
                  reward={item.reward}
                  to={item.alt}
                />
              </div>
            ))}
          </motion.div>
        ) : (
          <div className="flex flex-col gap-8 w-full">
            <div className="flex justify-between gap-8 w-full">
              <motion.div
                // variants={recentWorksVariants}
                // initial="workStart"
                // animate={animationControl}
                // ref={recentWorksRef}
                className="flex flex-col gap-8 lg:gap-24"
              >
                {recentWorksData.map((item, index) => {
                  if (index < 2) {
                    return (
                      <div>
                        <RecentWorksCard
                          key={item.alt}
                          header={item.header}
                          headerColor={item.headerColor}
                          text={item.text}
                          textColor={item.textColor}
                          bg={item.bg}
                          img={item.img}
                          alt={item.alt}
                          reward={item.reward}
                          to={item.alt}
                        />
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </motion.div>
              <motion.div
                // variants={recentWorksVariants}
                // initial="workStart"
                // animate={animationControl}
                // ref={recentWorksRef2}
                className="flex flex-col gap-8 lg:gap-24 translate-y-14"
              >
                {recentWorksData.map((item, index) => {
                  if (index > 1 && index < 4) {
                    return (
                      <div>
                        <RecentWorksCard
                          key={item.alt}
                          header={item.header}
                          headerColor={item.headerColor}
                          text={item.text}
                          textColor={item.textColor}
                          bg={item.bg}
                          img={item.img}
                          alt={item.alt}
                          reward={item.reward}
                          to={item.alt}
                        />
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </motion.div>
            </div>
            {/* <div className="flex  justify-between gap-8 lg:gap-24 flex-wrap mt-24">
              {recentWorksData.map((item, index) => {
                if (index > 3) {
                  return (
                    <RecentWorksCard
                      key={item.alt}
                      header={item.header}
                      headerColor={item.headerColor}
                      text={item.text}
                      textColor={item.textColor}
                      bg={item.bg}
                      img={item.img}
                      alt={item.alt}
                      reward={item.reward}
                      to={item.alt}
                    />
                  );
                }
              })}
            </div> */}
          </div>
        )}

        <LinksCard to={"/works"} text={"view more"} width={width} />
      </section>
      <section className=" sm:flex sm:flex-col sm:gap-6 w-full">
        <h2 className=" sm:pl-[15vw] sm:pr-[30vw] font-semibold text-xl sm:text-3xl lg:text-4xl pb-4">
          Who we are
        </h2>
        <div className="sm:self-center sm:w-1/2">
          <p className="text-xl sm:text-2xl  ">
            A tech agency with solid design and development expertise. We build
            readymade websites, mobile applications, and elaborate online
            business services.
          </p>

          <LinksCard to={"/services"} text={"Learn more"} width={width} />
        </div>
      </section>
    </div>
  );
};

export default LandingPage;
