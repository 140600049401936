import "./darkLogoStyle.css";

const DarkLogo = () => {
  return (
    <svg
      width="142"
      height="42"
      viewBox="0 0 142 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2986_2310)">
        <path
          d="M10.0946 41.4636C8.03667 41.4607 6.06405 40.6409 4.61023 39.1843C3.15641 37.7277 2.34032 35.7536 2.3413 33.6956V33.2773C2.34421 31.2219 3.16201 29.2515 4.61541 27.7981C6.06881 26.3447 8.03921 25.5269 10.0946 25.524H13.8117V23.5976C13.8136 22.1806 14.3776 20.8223 15.3799 19.8206C16.3822 18.819 17.7409 18.256 19.1579 18.255C20.6256 18.255 20.7871 17.822 20.7871 16.835C20.7871 15.8479 20.633 15.4113 19.1579 15.4113H9.44148C6.03266 15.5287 3.57053 14.7985 1.96335 13.184C0.598358 11.8557 -0.0621244 9.96961 0.000254508 7.59921C0.0798088 5.55971 0.944899 3.62997 2.41457 2.21364C3.88423 0.797319 5.84463 0.00413176 7.88568 0L18.3984 0C28.412 0 34.6352 5.69482 34.6352 14.8572C34.6352 23.6636 28.1111 29.5602 18.3984 29.5602H17.848V33.6956C17.8489 35.7536 17.0328 37.7277 15.579 39.1843C14.1252 40.6409 12.1526 41.4607 10.0946 41.4636ZM10.0946 29.5749C9.11164 29.5759 8.16897 29.9658 7.47251 30.6595C6.77606 31.3532 6.38242 32.2943 6.37758 33.2773V33.6956C6.37758 34.6814 6.76919 35.6269 7.46628 36.3239C8.16336 37.021 9.1088 37.4126 10.0946 37.4126C11.0804 37.4126 12.0259 37.021 12.723 36.3239C13.4201 35.6269 13.8117 34.6814 13.8117 33.6956V29.5602L10.0946 29.5749ZM17.848 25.5386H18.3984C27.3919 25.5386 30.5989 20.0346 30.5989 14.8719C30.5989 7.98083 26.1517 4.03628 18.3984 4.03628H7.88568C6.89442 4.03248 5.94013 4.41234 5.22265 5.09631C4.50516 5.78028 4.08012 6.71532 4.03653 7.70563C4.00351 8.93486 4.25303 9.79715 4.79976 10.3439C5.57399 11.1108 7.1408 11.474 9.3314 11.397H9.40112H19.1469C22.7025 11.397 24.8197 13.4335 24.8197 16.846C24.8197 20.2585 22.7062 22.2913 19.1579 22.2913C18.8111 22.2913 18.4785 22.4288 18.2329 22.6737C17.9874 22.9185 17.8489 23.2508 17.848 23.5976V25.5386Z"
          fill="black"
          className="svg-logo-element-1"
        ></path>
        <path
          d="M58.9402 16.879C58.9382 19.033 58.0817 21.0983 56.5586 22.6214C55.0355 24.1445 52.9703 25.001 50.8162 25.003C49.4648 24.9904 48.1365 24.6505 46.9451 24.0122V30.2098H42.7253V8.87619L46.9451 9.73848C48.1396 9.11242 49.4676 8.78389 50.8162 8.78078C51.8816 8.77837 52.937 8.986 53.9221 9.39181C54.9071 9.79761 55.8025 10.3936 56.557 11.1458C57.3116 11.8979 57.9104 12.7914 58.3193 13.7752C58.7282 14.7589 58.9392 15.8137 58.9402 16.879ZM54.6727 16.879C54.6727 16.1134 54.4457 15.3649 54.0203 14.7283C53.595 14.0917 52.9904 13.5955 52.283 13.3025C51.5756 13.0095 50.7973 12.9329 50.0463 13.0823C49.2954 13.2316 48.6056 13.6003 48.0642 14.1417C47.5229 14.6831 47.1542 15.3729 47.0048 16.1238C46.8554 16.8747 46.9321 17.6531 47.2251 18.3605C47.5181 19.0678 48.0143 19.6724 48.6509 20.0978C49.2875 20.5231 50.0359 20.7502 50.8016 20.7502C51.3103 20.7516 51.8144 20.6525 52.2847 20.4585C52.755 20.2644 53.1824 19.9794 53.5421 19.6196C53.9019 19.2598 54.187 18.8325 54.381 18.3622C54.575 17.8918 54.6742 17.3878 54.6727 16.879Z"
          fill="black"
          className="svg-logo-element-2"
        ></path>
        <path
          d="M76.0624 8.78078C74.4573 8.77715 72.8872 9.24994 71.551 10.1393C70.2148 11.0286 69.1726 12.2945 68.5563 13.7766C67.9401 15.2587 67.7775 16.8903 68.0892 18.4649C68.4009 20.0394 69.1729 21.486 70.3074 22.6215C71.4418 23.757 72.8878 24.5303 74.462 24.8434C76.0363 25.1566 77.6681 24.9955 79.1507 24.3806C80.6334 23.7657 81.9002 22.7246 82.7907 21.3892C83.6813 20.0538 84.1555 18.4841 84.1533 16.879C84.1456 14.7349 83.2909 12.6807 81.7755 11.1638C80.26 9.64699 78.2065 8.79045 76.0624 8.78078ZM76.0624 20.7428C75.3006 20.7399 74.5567 20.5115 73.9246 20.0863C73.2925 19.6611 72.8005 19.0582 72.5107 18.3537C72.2208 17.6492 72.1461 16.8747 72.296 16.1277C72.4458 15.3808 72.8136 14.695 73.3527 14.1569C73.8919 13.6187 74.5784 13.2523 75.3256 13.1038C76.0728 12.9554 76.8472 13.0316 77.5512 13.3228C78.2551 13.614 78.8571 14.1071 79.2811 14.74C79.7051 15.3729 79.9321 16.1172 79.9336 16.879C79.9336 17.387 79.8334 17.8901 79.6388 18.3593C79.4441 18.8286 79.1589 19.2549 78.7993 19.6138C78.4397 19.9726 78.0129 20.2571 77.5433 20.4508C77.0737 20.6446 76.5704 20.7438 76.0624 20.7428Z"
          fill="black"
          className="svg-logo-element-3"
        ></path>
        <path
          d="M93.3046 20.6144V24.8709H90.8718C90.1196 24.8713 89.3748 24.7235 88.6798 24.4358C87.9849 24.148 87.3535 23.7261 86.8218 23.1941C86.2901 22.662 85.8686 22.0304 85.5813 21.3353C85.294 20.6401 85.1467 19.8952 85.1476 19.143V13.129H83.7056V8.87618H85.1476V4.68579H89.4004V8.87618H93.1101L92.6331 13.129H89.4004V17.8661C89.397 18.2255 89.4648 18.582 89.5999 18.915C89.735 19.2481 89.9346 19.5511 90.1874 19.8066C90.4402 20.0621 90.741 20.265 91.0726 20.4037C91.4042 20.5423 91.76 20.614 92.1194 20.6144H93.3046Z"
          fill="black"
          className="svg-logo-element-4"
        ></path>
        <path
          d="M125.492 21.3189C124.553 22.7354 123.19 23.8194 121.599 24.4165C120.008 25.0135 118.269 25.0932 116.63 24.644C114.991 24.1949 113.535 23.24 112.471 21.9152C111.406 20.5905 110.787 18.9635 110.701 17.2662C110.615 15.5689 111.067 13.8877 111.993 12.4624C112.918 11.037 114.27 9.94002 115.855 9.32783C117.441 8.71563 119.179 8.61941 120.822 9.05288C122.466 9.48635 123.93 10.4274 125.007 11.7419L121.907 14.6774C121.546 14.164 121.067 13.7449 120.51 13.4556C119.953 13.1663 119.335 13.0153 118.707 13.0152C117.701 13.0462 116.747 13.4674 116.047 14.1896C115.347 14.9119 114.955 15.8784 114.955 16.8845C114.955 17.8906 115.347 18.8571 116.047 19.5794C116.747 20.3016 117.701 20.7229 118.707 20.7538C119.296 20.7535 119.877 20.6215 120.409 20.3676C120.94 20.1136 121.408 19.7441 121.778 19.2861L125.492 21.3189Z"
          fill="black"
          className="svg-logo-element-5"
        ></path>
        <path
          d="M142.003 20.6144V24.8708H139.379C139 24.8728 138.625 24.7996 138.274 24.6555C137.924 24.5114 137.606 24.2994 137.338 24.0315C137.07 23.7636 136.858 23.4453 136.714 23.095C136.57 22.7446 136.497 22.3692 136.499 21.9904V16.0093C136.499 15.2162 136.183 14.4556 135.623 13.8947C135.062 13.3339 134.301 13.0188 133.508 13.0188C132.715 13.0188 131.954 13.3339 131.393 13.8947C130.833 14.4556 130.517 15.2162 130.517 16.0093V24.8708H126.294V3.53357H130.517V9.40452C131.622 8.90816 132.832 8.69588 134.04 8.78699C135.247 8.87809 136.412 9.26971 137.429 9.92617C138.446 10.5826 139.283 11.4831 139.863 12.5456C140.444 13.608 140.749 14.7988 140.751 16.0093V20.0713C140.751 20.1428 140.765 20.2136 140.792 20.2797C140.819 20.3458 140.859 20.4059 140.909 20.4564C140.96 20.5069 141.02 20.5469 141.086 20.574C141.152 20.6011 141.223 20.6149 141.294 20.6144H142.003Z"
          fill="black"
          className="svg-logo-element-6"
        ></path>
        <path
          d="M68.7707 9.00458L68.4038 13.3858C67.9656 13.1343 67.4669 13.0074 66.9617 13.0188C66.567 13.0184 66.176 13.0958 65.8111 13.2466C65.4463 13.3975 65.1148 13.6188 64.8357 13.898C64.5565 14.1771 64.3352 14.5086 64.1843 14.8734C64.0335 15.2383 63.9561 15.6293 63.9565 16.024V24.8708H59.7478V8.87616L63.9712 9.41922C64.917 8.9977 65.941 8.78015 66.9764 8.78075C67.5819 8.77349 68.1856 8.84879 68.7707 9.00458Z"
          fill="black"
          className="svg-logo-element-7"
        ></path>
        <path
          d="M105.262 18.4641L109.712 18.3247C109.924 17.2181 109.884 16.0782 109.595 14.9893C109.595 14.9452 109.573 14.9085 109.558 14.8645C109.128 13.2222 108.193 11.7565 106.885 10.6743C105.577 9.59216 103.962 8.94824 102.268 8.83348C100.575 8.71872 98.8875 9.13893 97.4454 10.0348C96.0034 10.9306 94.8791 12.2568 94.2315 13.8261C93.8824 14.6814 93.6802 15.5894 93.6334 16.512C93.6224 16.7259 93.6224 16.9403 93.6334 17.1542C93.6334 17.2973 93.6334 17.433 93.6554 17.5762C93.6811 17.833 93.7141 18.0862 93.7618 18.332C93.7632 18.3528 93.7632 18.3736 93.7618 18.3944C94.0024 19.7234 94.5726 20.9708 95.4201 22.0223C96.2676 23.0739 97.3655 23.8959 98.613 24.4133C99.8606 24.9306 101.218 25.1267 102.561 24.9836C103.904 24.8404 105.19 24.3626 106.3 23.5939C107.262 22.938 108.07 22.0818 108.67 21.084C107.628 20.5483 106.058 19.7374 104.887 19.143C104.533 19.597 104.106 19.99 103.625 20.3061C103.124 20.5833 102.567 20.7446 101.996 20.7782C101.424 20.8119 100.853 20.717 100.323 20.5006C99.4669 20.1398 98.7645 19.4907 98.3375 18.6659L102.106 18.5522L105.262 18.4641ZM98.2017 15.3562C98.6099 14.4316 99.3633 13.7031 100.301 13.326C101.239 12.949 102.287 12.9533 103.221 13.3381C103.538 13.4686 103.835 13.6414 104.106 13.8518C104.549 14.1999 104.911 14.6392 105.17 15.1397L101.236 15.2608L98.2017 15.3562Z"
          fill="black"
          className="svg-logo-element-8"
        ></path>
        <path
          d="M98.2351 36.1908H97.171L96.6023 34.8882H93.9126L93.3439 36.1908H92.2834L94.852 30.074H95.6336L98.2351 36.1908ZM94.2796 33.8974H96.239L95.2519 31.4573L94.2796 33.8974Z"
          fill="black"
          className="svg-logo-element-9"
        ></path>
        <path
          d="M102.954 36.1908H100.496V30.0887H102.954C103.607 30.0531 104.251 30.258 104.763 30.6648C105.215 31.0317 105.442 31.6445 105.442 32.4628V33.8314C105.469 34.1638 105.423 34.4981 105.305 34.8103C105.188 35.1224 105.003 35.4047 104.763 35.6367C104.246 36.032 103.604 36.2286 102.954 36.1908ZM101.508 35.2001H102.976C103.175 35.2157 103.375 35.1912 103.564 35.1282C103.753 35.0652 103.927 34.9648 104.077 34.8332C104.343 34.5207 104.475 34.1159 104.444 33.7067V32.6059C104.465 32.3927 104.443 32.1775 104.38 31.9727C104.317 31.768 104.214 31.5778 104.077 31.4133C103.927 31.282 103.753 31.1819 103.564 31.1189C103.375 31.0559 103.175 31.0312 102.976 31.0464H101.508V35.2001Z"
          fill="black"
          className="svg-logo-element-10"
        ></path>
        <path
          d="M110.49 36.1907H109.712L107.401 30.0886H108.443L110.105 34.657L111.76 30.0886H112.802L110.49 36.1907Z"
          fill="black"
          className="svg-logo-element-11"
        ></path>
        <path
          d="M119.818 36.1908H118.753L118.185 34.8882H115.499L114.926 36.1908H113.866L116.434 30.074H117.216L119.818 36.1908ZM115.866 33.8974H117.821L116.834 31.4573L115.866 33.8974Z"
          fill="black"
          className="svg-logo-element-12"
        ></path>
        <path
          d="M127.305 36.1907H126.413L123.085 31.7875V36.1907H122.08V30.0886H122.971L126.3 34.3854V30.0886H127.305V36.1907Z"
          fill="black"
          className="svg-logo-element-13"
        ></path>
        <path
          d="M134.21 35.743C133.707 36.1139 133.088 36.2946 132.464 36.2531C131.804 36.2897 131.153 36.0878 130.629 35.6843C130.386 35.4562 130.198 35.1766 130.078 34.8661C129.958 34.5555 129.909 34.2219 129.935 33.89V32.3746C129.909 32.041 129.957 31.7056 130.077 31.3932C130.197 31.0807 130.386 30.7992 130.629 30.5693C131.154 30.1681 131.804 29.9665 132.464 30.0005C132.9 29.9951 133.333 30.0723 133.741 30.228C134.088 30.3684 134.39 30.6036 134.61 30.9068C134.826 31.232 134.933 31.6177 134.915 32.0076V32.1104H133.814V32.0443C133.813 31.9876 133.807 31.9311 133.796 31.8756C133.777 31.7761 133.747 31.6789 133.708 31.5857C133.669 31.4769 133.604 31.3788 133.52 31.2995C133.21 31.0759 132.829 30.9729 132.449 31.0096C132.037 30.9819 131.63 31.1132 131.311 31.3765C131.169 31.5403 131.061 31.7316 130.995 31.9385C130.928 32.1455 130.905 32.3638 130.926 32.5801V33.8056C130.908 34.0079 130.931 34.2117 130.994 34.4048C131.057 34.5978 131.159 34.776 131.293 34.9284C131.619 35.1984 132.038 35.3301 132.46 35.2954C132.659 35.299 132.858 35.283 133.054 35.2477C133.207 35.2167 133.351 35.1528 133.476 35.0605C133.594 34.9715 133.688 34.8559 133.752 34.7232C133.816 34.5905 133.847 34.4445 133.843 34.2973V34.2349L133.92 34.1946H134.867V34.268C134.867 34.3193 134.867 34.3634 134.867 34.4001C134.859 34.4875 134.847 34.5744 134.83 34.6606C134.811 34.7855 134.781 34.9083 134.739 35.0275C134.683 35.156 134.616 35.2788 134.537 35.3945C134.444 35.525 134.335 35.6423 134.21 35.743Z"
          fill="black"
          className="svg-logo-element-14"
        ></path>
        <path
          d="M141.816 36.1907H137.413V30.0886H141.816V31.0757H138.426V32.5911H140.939V33.5818H138.426V35.2147H141.816V36.1907Z"
          fill="black"
          className="svg-logo-element-15"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_2986_2310">
          <rect
            width="142"
            height="41.4489"
            fill="black"
            className="svg-logo-element-16"
          ></rect>
        </clipPath>
      </defs>
    </svg>
  );
};

export default DarkLogo;
