// import { Link } from "react-router-dom";
// import { MdKeyboardArrowRight } from "react-icons/md";

import laptop from "../../assets/join/laptop.svg";
import office from "../../assets/join/office.svg";
import laptopLg from "../../assets/join/laptopLg.svg";
import officeLg from "../../assets/join/officeLg.svg";
import { useContext } from "react";
import widthContext from "../../context/widthContext";
import { motion } from "framer-motion";

const JoinUs = () => {
  const width = useContext(widthContext);
  const variants = {
    initial: {
      scale: 0.8,
      opacity: 0,
      x: 100,
    },
    animate: {
      scale: 1,
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.5,
      },
    },
  };
  return (
    <main className="flex flex-col items-center justify-center px-4  sm:px-8 lg:px-16 gap-16">
      <motion.h2
        variants={variants}
        initial="initial"
        animate="animate"
        className="text-2xl sm:text-3xl md:text-4xl lg:text-6xl font-medium self-start"
      >
        Current <br /> Openings
      </motion.h2>
      <section className="w-full">
        <div className="flex justify-center items-center text-center bg-[#EFEFEF] h-[20rem] w-[20rem] sm:w-full ">
          <p className="text-2xl">
            No open position yet, please check back again.
          </p>
        </div>
      </section>
      <h4 className="text-xl sm:text-2xl sm:w-1/2 sm:self-start">
        <span className="font-semibold">To apply,</span> send us an e-mail to
        hello@protechadvance.com with your experience or portfolio and why you’d
        like to collaborate.
      </h4>
      <div className=" flex flex-col sm:flex-row  overflow-hidden w-full self-center justify-center gap-8 m-0">
        {/* images for job openings */}
        <div className=" ">
          <img
            className=" h-[320px]"
            src={width < 640 ? laptop : laptopLg}
            alt=""
          />
        </div>
        <div className=" ">
          <img
            className=" h-[320px] m-0"
            src={width < 640 ? office : officeLg}
            alt=""
          />
        </div>
      </div>
    </main>
  );
};

export default JoinUs;
