import { useParams } from "react-router-dom";
import { caseStudiesData } from "../../DummyData";
import { CaseStudiesPage } from "../../components/Cards";
import { useContext } from "react";
import widthContext from "../../context/widthContext";

const caseStudiesLinks = {
  runng: "runng",
  agrolink: "agrolink",
  d4ulogistics: "d4ulogistics",
  esanFinance: "esanFinance",
  bedspace: "bedspace",
  giftcardstonaira: "giftcardstonaira",
  blackjoe: "blackjoe",
  gasmonkey: "gasmonkey",
  shop4me: "shop4me",
  currenxi: "currenxi",
};


const CaseStudies = () => {
  const width = useContext(widthContext);
  let { workId } = useParams();
  let routePath = workId.replace(/\s+/g, "").trim().substring(1);
  let caseStudy;
  let pagesObject = {};

  // function createVar(name, value){
  //   // alert(name)
  //   // this[name] = (typeof value !== 'undefined')?value:"";
  //  this[name] = "homeeee";
  //   pagesObject.push(name);
  //   console.log(pagesObject[0], "objectssssss");
  // };

  for (let i = 0; i < caseStudiesData.length; i++) {
    let page = (
      <CaseStudiesPage
        name={caseStudiesData[i].title}
        header1={caseStudiesData[i].header1}
        header2={caseStudiesData[i].header2}
        headerImg={caseStudiesData[i].headerImg}
        about={caseStudiesData[i].about}
        services={caseStudiesData[i].services}
        launchLink={caseStudiesData[i].launchLink}
        img1={caseStudiesData[i].img1}
        img2={caseStudiesData[i].img2}
        img3={caseStudiesData[i].img3}
        img4={caseStudiesData[i].img4}
        previousLink={caseStudiesData[i].prev}
        nextLink={caseStudiesData[i].next}
        width={width}
      />
    );

    pagesObject[caseStudiesData[i].name] = page;
    // createVar(caseStudiesData[0].name, test);
    // createVar("name66666", "home");
  }
 

 

  for (const key in pagesObject) {
    if (key === routePath) {
      caseStudy = pagesObject[key];
    }
  }
  // switch (routePath) {
  //   case caseStudiesLinks.runng:
  //     caseStudy = pagesObject[0];
  //     break;
  //   case caseStudiesLinks.agrolink:
  //     caseStudy = pagesObject[1];
  //     break;

  //   case caseStudiesLinks.d4ulogistics:
  //     caseStudy = pagesObject[2];
  //     break;
  //   case caseStudiesLinks.SellBitFast:
  //     caseStudy = myRewardPage;
  //     break;

  //   default:
  //     break;
  // }

  // console.log(workId)
  return caseStudy;
  // <Routes>{<Route path=":myReward" element={myRewardPage} />}</Routes>;
};

export default CaseStudies;
