import { Link } from "react-router-dom";
import Icon from "../assets/Icon.svg";

const Footer = () => {
  // dauiauhdiau
  return (
    <footer className="px-4 bg-[#121212] flex flex-col gap-[6rem] justify-center items-center pt-[5rem] pb-8 text-white">
      <div className="flex flex-col gap-12 ">
        <h1 className="font-semibold text-center text-2xl md:text-3xl lg:text-4xl">
          Have a project in mind? <br />
          Let’s get to work.
        </h1>
        <Link
          to="/contact"
          className="py-5 px-8 rounded-[40px] bg-white text-black w-fit self-center hover:scale-105 transition-transform"
        >
          Start a project
        </Link>
      </div>
      <div className="flex items-center gap-1 justify-center">
        <img src={Icon} alt="protech icon" />
        <p className="text-[10px] md:text-sm">
          {`Protech Advance Limited. © ${new Date().getFullYear()} All rights reserved.`}        </p>
      </div>
    </footer>
  );
};

export default Footer;
